import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Footer from '../components/Footer'
import Theme from '../components/Theme'
import About from '../components/About'
import Profile from '../components/Profile'
import Header from '../components/Header'
import Modal from '../components/Modal'
import StepModal from '../components/StepModal'
import pic_fujii from '../images/Fujii_Naotaka.gif'
import pic_yoshida from '../images/yoshida.jpg'
import pic_toyoda from '../images/Toyoda_Keisuke.jpg'
import pic_uka from '../images/Todoli_Uka.png'
import pic_twitter from '../images/Twitter_Social_Icon_Square_White.png'
import pic_ochiai from '../images/Ochiai_Yoichi.jpg'
import Logo from '../images/top_mainimage.png'

class SecondPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isArticleVisible: false,
            timeout: false,
            articleTimeout: false,
            article: '',
            loading: 'is-loading',
        }
        this.handleOpenArticle = this.handleOpenArticle.bind(this)
        this.handleCloseArticle = this.handleCloseArticle.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: '' });
        }, 100);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOpenArticle(article) {

        this.setState({
            isArticleVisible: !this.state.isArticleVisible,
            article
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                articleTimeout: !this.state.articleTimeout
            })
        }, 350)

    }

    handleCloseArticle() {

        this.setState({
            articleTimeout: !this.state.articleTimeout
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                isArticleVisible: !this.state.isArticleVisible,
                article: ''
            })
        }, 350)

    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isArticleVisible) {
                this.handleCloseArticle();
            }
        }
    }



    render() {
        return (
            <Layout location={this.props.location} >
                <div>
                    <Header />
                    <div id="outlinetop">
                        
                    <div id="wrapper-theme" className={`wrapper-theme ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>

                        <Theme onOpenArticle={this.handleOpenArticle} timeout={this.state.timeout} />
                        <About
                            isArticleVisible={this.state.isArticleVisible}
                            timeout={this.state.timeout}
                            articleTimeout={this.state.articleTimeout}
                            article={this.state.article}
                            onCloseArticle={this.handleCloseArticle}
                            setWrapperRef={this.setWrapperRef}
                        />
                       
                        </div>
                    </div>
                    <div id="register">
                        <div className="rinjinews"> VRAA02ポータルワールドをオープンしました！<br />
                            ご応募いただいた全89作品にこちらのワールドからアクセスできます。→ワールドは<a href="https://vrchat.com/home/launch?worldId=wrld_4165c26e-b033-4eea-a469-e5247048e39a&instanceId=91724&shortName=vanilla-chrissaxophone-6c786" target="_blank"><u>こちら</u></a></div>
                        </div>
                    <div id="step">
                        <div className="sectiontitle">提出完了までは4ステップ</div>
                        <div className="notes">注意：VRAA02参加には公開されているTwitterアカウントが必須です</div>
                        <div className="stepflow">
                            <div className="stepbutton">
                                <div className="steptitle">STEP1</div>
                                <StepModal
                                    name='STEP1'
                                    title='期間内に「参加登録受付」を行ってください。'
                                    text='「登録受付フォーム」から参加登録！<br>
[フォーム項目]<br>
                                    ・代表者名<br>
                                    ・メールアドレス<br>
                                    ・Twitterユーザー名<br>
                                    ・注意事項/禁止事項等への同意チェック'
                                    stepnotesurl='https://docs.google.com/forms/d/e/1FAIpQLSc3SR8NwCPdjRBhfpKBjFsOyTSvw-il04RQ_7S2-Vqmn1Tpzw/viewform?usp=sf_link'
                                    stepnotes='登録受付'
                                    buttontext='参加登録'
                                />
                            </div>
                            <div className="stepbutton">
                                <div className="steptitle">STEP2</div>
                                <StepModal
                                    name='STEP2'
                                    title='募集テーマをもとに、自由にワールドをつくってください。既に制作済みのワールドでも問題ありません。'
                                    text='
                                    ※ワールドはPrivate/CommunityLabs/Publicのどれでも構いません。<br>
                                    ※可能であれば応募作品のワールド内に配布物を設置してください。なお設置の有無による審査上の優劣はありません。(配布物の詳細については「ポータルキットをダウンロードする」リンク先のREAD MEに記載しています。）<br>
                                    ***<br>
                                    ●VRCSDK2利用の方<br>
                                    StandardAssetsを導入の上「VRAA02_Portalkit.unitypackage」をご使用ください。<br>
                                    <br>
                                    ●VRCSDK3+UDON利用の方<br>
                                    StandardAssets、UdonSharpを導入の上「VRAA02_Portalkit_Udon.unitypackage」をご使用ください。                                    '
                                    stepnotesurl='https://drive.google.com/drive/folders/1xPehMNpIQrc9Tu_YNkmyvLpf9MdYNQgO?usp=sharing'
                                    stepnotes='ポータルキットをダウンロードする'
                                    buttontext='作品制作'
                                />
                            </div>
                            <div className="stepbutton">

                                <div className="steptitle">STEP3</div>
                                <StepModal
                                    name='STEP3'
                                    title='作品のアップロードが完了したら、Twitterでハッシュタグ「#VRAA02参加作品」を明記の上「作品紹介ツイート」を行ってください。ツイートも審査対象となります。'
                                    text='※上記ハッシュタグのついた1ツイートのみを審査対象とします。<br>作品の内容や魅力が十分に伝わるツイートを心掛けてください。<br>ツイート内の文章、画像、動画等の使用は自由です。<br>
                                    ※動画はTwitterへ動画ファイルを直接アップロードしたツイートのみが審査の対象となります。外部サイトへのリンクの掲示は対象外です。<br>
                                    ※対象ツイートは画像化の上、ポータルワールドと当サイトの作品一覧に使用します。また、ツイート内の動画はワールド内で再生するため、運営委員会にてダウンロードを行います。ワールド掲載以外の目的では使用しません。'
                                    stepnotesurl='https://twitter.com/search?q=%23VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81&src=typed_query'
                                    stepnotes='ツイートする'
                                    buttontext='作品ツイート'
                                />
                            </div>
                            <div className="stepbutton">
                                <div className="steptitle">STEP4</div>
                                <StepModal
                                    name='STEP4'
                                    title='期間内に「作品提出受付」を完了してください。受付には上記ツイートのURLが必要となります。'
                                    text='[フォーム項目]<br>
                                    ・登録者ID<br>
                                    ・登録済み代表者名<br>
                                    ・登録済みメールアドレス<br>
                                    ・作品タイトル<br>
                                    ・「作品紹介ツイート」URL<br>
                                    ・ワールド名 ※プラットフォーム上で検索可能なワールド名をご記入ください。<br>
                                    ・ワールドBlueprintID ※VRChatワールド作品のみ'
                                    stepnotesurl='https://docs.google.com/forms/d/e/1FAIpQLSfQ2n7oxU4_gtf94GNyZhwR8Dma9qfZ-WJtYMHbSX2R4q5eqQ/viewform?usp=sf_link'
                                    stepnotes='提出受付'
                                    buttontext='提出受付'
                                />
                            </div>
                        </div>
                        </div>
                    <div id="wrapper-profile">
                        <div className="sectiontitle">審査員</div>
                        <div className="profile-box">
                            <div id="profile">
                                <Profile
                                    name='藤井直敬'
                                    image={pic_fujii}
                                    title1='XRコンソーシアム代表理事'
                                    title2='ハコスコ 代表取締役'
                                    title3='デジタルハリウッド大学 教授'
                                />
                                <div className="profile_twitter"><a href="https://twitter.com/NaotakaFujii" target="_blank"><img src={pic_twitter} width="20%" /></a></div>
                            </div>
                            <div id="profile">
                                <Profile
                                    name='落合陽一'
                                    image={pic_ochiai}
                                    title1='XRコンソーシアム理事'
                                    title2='メディアアーティスト'
                                    title3='筑波大学准教授'
                                />
                                <div className="profile_twitter"><a href="https://twitter.com/ochyai" target="_blank"><img src={pic_twitter} width="20%" /></a></div>
                            </div>
                            <div id="profile">
                                <Profile
                                    name='吉田修平'
                                    image={pic_yoshida}
                                    title1='XRコンソーシアム理事'
                                    title2='ソニー・インタラクティブエンタテインメント インディーズ イニシアチブ 代表'
                                    title3=''
                                />
                                <div className="profile_twitter"><a href="https://twitter.com/yosp" target="_blank"><img src={pic_twitter} width="20%" /></a></div>
                            </div>
                            <div id="profile">
                                <Profile
                                    name='豊田啓介'
                                    image={pic_toyoda}
                                    title1='建築家'
                                    title2='noiz共同主宰'
                                    title3='gluon共同主宰'
                                />
                                <div className="profile_twitter"><a href="https://twitter.com/toyoda_noiz" target="_blank"><img src={pic_twitter} width="20%" /></a></div>
                            </div>
                            <div id="profile">
                                <Profile
                                    name='届木ウカ'
                                    image={pic_uka}
                                    title1='バーチャルドール'
                                    title2='3Dモデラー'
                                    title3='AI×SFプロジェクトサポーター'
                                />
                                <div className="profile_twitter"><a href="https://twitter.com/todoki_uka" target="_blank"><img src={pic_twitter} width="20%" /></a></div>
                            </div>

                        </div>
                        </div>
                    <div id="outline">
                        
                        <div className="sectiontitle">審査対象</div>
                        
                        <div ref={this.props.setWrapperRef} className="outlinebox"><div className="outlinedetail">VRChatワールド＋提出フォーム内容
                            <Modal
                                    name=''
                                title='こういうワールドは応募できますか？'
                                text='Q: 過去につくったワールドだけど、応募できる？<br>
                                A: Public/Privateに関わらず応募できます。<br>
                                <br>***<br>
                                Q: イベントで使ったワールドだけど、跡地でもいい？<br>
                                A: 大丈夫です。応募時に開催時の様子がわかる動画や画像付きツイートがあり、ワールド内でその体験の一部に触れられるギミック等があるとなおよいです。<br>
                                <br>***<br>
                                Q: VR空間制作ははじめてでもOK？<br>
                                A: もちろんOKです。<br>
                                <br>***<br>
                                Q: ギミックのない純粋な空間だけのワールドでもよい？<br>
                                A: 問題ありません。<br>
                                <br>***<br>
                                Q: テーマとまったく関係ないけどOK？<br>
                                A: 何かしらテーマとの繋がりを考えていただきたいです。<br>
                                <br>***<br>
                                Q: QuestOnlyはOK？<br>
                                A: 応募は可能ですが、審査対象外となります。ポータルワールドや当サイトへの作品掲示のみ行います。
                                <br>***<br>
                                Q: VRChat以外のプラットフォームでもOK？<br>
                                A: 応募は可能ですが、審査対象外となります。ポータルワールドや当サイトへの作品掲示のみ行います。'                       
                                buttontext='こういうワールドは応募できますか？'
                            /></div>
                            </div>
                        </div>
                    <div id="outline">
                        <div className="sectiontitle">審査方法</div>
                        <div className="outlinebox"><div className="outlinedetail">運営による一次審査でファイナリスト最大10作品を選定後、審査員による二次審査を行い受賞作品を決定します。<br />すべての審査は原則VRにて行います。</div>
                        </div>
                        </div>
                    <div id="outline">
                        <div className="sectiontitle">賞</div>
                        <div className="outlinebox"><div className="outlinedetail"><b>大賞</b>　1作品　賞金5万円<br /><br /><b>審査員賞</b>　5作品　各賞金2万円<br />※今後変更する可能性があります。ご了承ください。</div>
                        </div>
                        </div>
                        <div id="outline">
                        <div className="sectiontitle">スケジュール</div>
                        <div className="outlinebox"><div className="outlinedetail"><b>登録受付</b>：2020年5月31日（日）～<font color="red">7月19日（日）23:59まで</font><br /><br />
                            <b>提出受付</b>：2020年5月31日（日）～<font color="red">7月19日（日）23:59まで</font><br /><br />
                            <b>一次審査・ポータルワールド一次OPEN</b>：7月下旬（予定）<br /><br />
                            <b>一次審査結果発表</b>：8月上旬（予定）<br /><br />
                            <b>二次審査</b>：8月上旬<br /><br />
                            <b>二次審査結果発表（授賞式）</b>：8月末（詳細は後日お知らせします）<br /><br />
                            <b>ポータルワールド二次OPEN</b>：9月上旬（予定）</div>

                        </div>

                        </div>
                    <div id="outline">
                        <div className="sectiontitle">作品展示について</div>
                        <div className="outlinebox"><div className="outlinedetail">VRChat上のVRAAポータルワールドに応募作品を掲示の上、各作品（VRChatワールドに限る）への移動ポータルを設けます。<br />また、当サイト内に作品一覧を掲示します。</div>
                        </div>
                    </div>
                    <div id="outline">
                        <div className="sectiontitle">応募要項</div>
                        <div className="outlinebox"><div className="outlinedetail">
                            <h3>登録期間・提出期間</h3><br />
                            2020/5/31～<font color="red">7/19 23:59</font>
<br /><br />
                            <h3>提出の手順</h3>
                            <b>STEP1：期間内に「参加登録受付」を行ってください。</b>
                            <div className="yokobox">
                            [フォーム項目]<br />
                            ・代表者名<br />
                            ・メールアドレス<br />
                            ・Twitterユーザー名<br />
                            ・注意事項/禁止事項等への同意チェック<br />
                                <a class="stepnotesurl" href="https://docs.google.com/forms/d/e/1FAIpQLSc3SR8NwCPdjRBhfpKBjFsOyTSvw-il04RQ_7S2-Vqmn1Tpzw/viewform?usp=sf_link" target="_blank"><button className="yoko">登録受付</button></a><br />
                            </div>
                            <b>STEP2：募集テーマをもとに、自由にワールドをつくってください。既に制作済みのワールドでも問題ありません。</b>
                            <div className="yokobox">
                            ※ワールドはPrivate/CommunityLabs/Publicのどれでも構いません。<br />
                                ※可能であれば応募作品のワールド内に配布物を設置してください。なお設置の有無による審査上の優劣はありません。(配布物の詳細については「ポータルキットをダウンロードする」リンク先のREAD MEに記載しています。）<br />
                            ***<br />
                            ●VRCSDK2利用の方<br />
                            StandardAssetsを導入の上「VRAA02_Portalkit.unitypackage」をご使用ください。<br />
                            ●VRCSDK3+UDON利用の方<br />
                            StandardAssets、UdonSharpを導入の上「VRAA02_Portalkit_Udon.unitypackage」をご使用ください。<br />
                            <a class="stepnotesurl" href="https://drive.google.com/drive/u/2/folders/1xPehMNpIQrc9Tu_YNkmyvLpf9MdYNQgO?usp=sharing" target="_blank"><button className="yoko">ポータルキットをダウンロードする</button></a><br />
                            </div><br />
                            <b>STEP3：作品のアップロードが完了したら、Twitterでハッシュタグ「#VRAA02参加作品」を明記の上「作品紹介ツイート」を行ってください。ツイートも審査対象となります。</b>
                            <div className="yokobox">
                            ※上記ハッシュタグのついた1ツイートのみを審査対象とします。<br />
                            作品の内容や魅力が十分に伝わるツイートを心掛けてください。<br />
                            ツイート内の文章、画像、動画等の使用は自由です。<br />
                            ※動画はTwitterへ動画ファイルを直接アップロードしたツイートのみが審査の対象となります。外部サイトへのリンクの掲示は対象外です。<br />
                            ※対象ツイートは画像化の上、ポータルワールドと当サイトの作品一覧に使用します。また、ツイート内の動画はワールド内で再生するため、運営委員会にてダウンロードを行います。ワールド掲載以外の目的では使用しません。<br />
                                <a class="stepnotesurl" href="https://twitter.com/search?q=%23VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81&src=typed_query" target="_blank"><button className="yoko">ツイートする</button></a><br />
                            </div>
                            <b>STEP4：期間内に「作品提出受付」を完了してください。受付には上記ツイートのURLが必要となります。</b>
                            <div className="yokobox">
                            [フォーム項目]<br />
                            ・登録者ID<br />
                            ・登録済み代表者名<br />
                            ・登録済みメールアドレス<br />
                            ・作品タイトル<br />
                            ・「作品紹介ツイート」URL<br />
                            ・ワールド名 ※プラットフォーム上で検索可能なワールド名をご記入ください。<br />
                                ・ワールドBlueprintID ※VRChatワールド作品のみ<br />
                                <a class="stepnotesurl" href="https://docs.google.com/forms/d/e/1FAIpQLSfQ2n7oxU4_gtf94GNyZhwR8Dma9qfZ-WJtYMHbSX2R4q5eqQ/viewform" target="_blank"><button className="yoko">提出受付</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="form">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScN_Tzxm98n5cbjBGxMhFFRTv3KT8fZ9zeGo1QilYnYFVH_og/viewform?usp=sf_link" target="_blank"><button className="qaform">問い合わせはこちらから</button></a>
                        </div>
                            <div id="wrapper-footer">
                                <Footer timeout={this.state.timeout} />
                            </div>
                    <div id="bg"></div>
                </div>
                <Helmet>
                    <html lang="jp" />
                    <title>VRAA02概要</title>
                    <meta name="description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta property="og:site_name" content="VRAA02" />
                    <meta property="og:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="VRAA02" />
                    <meta name="twitter:description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。今年のテーマは「Live/Frontier」新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！" />
                    <meta name="twitter:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                </Helmet>
            </Layout>
        )
    }
}

export default SecondPage
